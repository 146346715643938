import React from "react"
import Album from "../components/album"

export default (props) => {
  const formatsAvailable = "digital download"
  return (
    <Album
      title="Your Crooked Part"
      recordedDate="Feb 2012"
      recordedLocation="The Pound Pad, Jefferson, GA"
      engineers="Will Manning"
      releaseDate="July 10, 2013"
      recordLabel="Shoulder Tap Records"
      catalogNumber="SHT010"
      formatsAvailable={formatsAvailable}
      location={props.location} />
  )
}
